import DottedMap from "dotted-map/without-countries";
import React, { useState } from "react";
import styled from "styled-components";
import { isTablet } from "../../hooks/checkMobile";
import { useSiteMetadata } from "../../hooks/useQuerydata";
import imgAbout from "../../images/wm.png";
import { color } from "../../shared/style";
import "../../styles/map.css";

const MapPoint = ({ x, y, data, maxW, actived, onHover }) => {
  const [showTip, setShowTip] = useState(false);
  const onMouseEnter = (data) => {
    setShowTip(true);
    onHover({
      x: x,
      y: y,
      data: data,
    });
  };
  const handleClick = (data) => {
    !isTablet && onHover(null);
    // console.log("click");
    // to do handle click here
  };
  const pointRadius = data?.dotted ? 1 : 0.3;

  //   const ref = useRef(null);
  //   const [coordX, setCoordinateX] = useState(0);
  //   const [coordY, setCoordinateY] = useState(0);
  //   const [widthText, setWidthText] = useState(0);
  //   useLayoutEffect(() => { // todo if tooltip over viewport
  //     if (showTip) {
  //       if (ref.current.getBBox().width + 1 + ref.current.getBBox().x >= maxW) {
  //         setCoordinateX(
  //           ref.current.getBBox().width + 1 + ref.current.getBBox().x - maxW + 1
  //         );
  //       }
  //       if (ref.current.getBBox().y < 0) setCoordinateY(6);
  //       setWidthText(ref.current.getBBox().width + 1);
  //     }
  //   }, [showTip, maxW]);

  return (
    <g className="group_dot">
      {actived && (
        <circle
          cx={x + 0.25}
          cy={y + 2}
          r={pointRadius}
          strokeWidth={data?.dotted ? 2 : 0}
          fill="red"
          stroke="#F4C2C2"
        />
      )}
      <circle
        cx={x + 0.25}
        cy={y + 2}
        r={showTip ? 0.75 : 0.6}
        className={data?.dotted && "circleEffect"}
        onMouseLeave={() => {
          // onHover(null);
          !actived && data?.dotted && setShowTip(false);
        }}
        onMouseEnter={() =>
          !actived && data?.dotted && onMouseEnter(data?.dotted)
        }
        onClick={() => data?.dotted && handleClick(data?.dotted)}
        strokeWidth={showTip ? 0.6 : 0.3}
        fill={
          actived
            ? "red"
            : data?.dotted
            ? color.secondary.danube
            : color.secondary.danube
        }
        stroke={
          actived ? "color.primary.white" : showTip ? "#c2e3fe" : "#E9ECF1"
        }
      />
    </g>
  );
};
const Map = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent;
`;
const pageType = {
  index: "index",
  contact: "contact",
  about: "about",
};
export const LocationMap = ({ geolocations, actived, page }) => {
  const [tooltip, setTooltip] = useState(null);
  const { allDataJson } = useSiteMetadata();
  const data = allDataJson.nodes.find((item) => item.map_about !== null)?.map_about;
  // const data = dataMap && dataMap.map_about;
  const newData = { ...data, points: JSON.parse(data.points) };
  const map = new DottedMap({ map: newData });
  for (const item of geolocations) {
    map.addPin({
      lat: item?.geolocation?.latitude || item?.latitude,
      lng: item?.geolocation?.longitude || item?.longitude,
      data: {
        dotted: {
          city: item?.city?.text,
          country: item?.country?.text,
          phone: item?.phone?.text,
          email: item?.email?.text,
        },
      },
    });
  }
  const points = map.getPoints();
  const showTip = (data) => {
    setTooltip(data);
  };
  return (
    <Map>
      <svg
        viewBox={`0 0 ${map.image.width} ${map.image.height}`}
        style={{ height: "100%" }}
      >
        <rect
          width={map.image.width}
          height={map.image.height}
          style={{ opacity: page === pageType.about ? 0.6 : "0.2" }}
          fill="url(#pattern0)"
        />
        <defs>
          <pattern
            id="pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0"
              transform="translate(0, -0.00290842) scale(0.000260417, 0.000433168)"
            />
          </pattern>
          <image
            id="image0"
            width="4000"
            height="2322"
            href={page === pageType.about ? imgAbout : imgAbout}
            // href="https://www.pngplay.com/wp-content/uploads/2/World-Map-PNG-Photos.png"
          />
        </defs>
        {Object.values(points).map((point, i) => {
          if (point?.data?.dotted)
            return (
              <MapPoint
                key={i}
                {...point}
                actived={actived}
                maxW={map.image.width}
                onHover={(data) => showTip(data)}
                // onSort={(text) => onSortGeos(text)}
              />
            );
          return null;
        })}

        {!actived &&
          tooltip && ( //&& page !== "home"
            <g className="tooltip2">
              {/* <rect x={x - coordX} y={y - 8 + coordY} width={widthText} height="6" stroke="gray" strokeWidth="0.01" fill={color.primary.white} style={{ backgroundColor: 'white' }} fontSize="2"></rect> */}
              <rect
                x={tooltip.x - 14.5}
                y={tooltip.y - 19.5}
                width={28.83}
                height={18.07}
                fill="#3DAE73"
              />
              <rect
                x={tooltip.x - 13.5}
                y={tooltip.y - 20.5}
                width={28.83}
                height={18.07}
                fill="#204370"
              />
              <text
                x={tooltip.x - 12}
                y={tooltip.y - 14}
                fill={color.primary.white}
                className="country"
                fontSize="2.95"
              >
                {tooltip?.data?.city}
              </text>
              <text
                x={tooltip.x - 12}
                y={tooltip.y - 11.5}
                fill={color.primary.white}
                className="city"
                fontSize="1.35"
              >
                {tooltip?.data?.country}
              </text>
              <line
                x1={tooltip.x - 12}
                y1={tooltip.y - 10}
                x2={tooltip.x + 14}
                y2={tooltip.y - 10}
                stroke-width="0.05"
                stroke="#FFFFFF"
              ></line>
              <text
                x={tooltip.x - 12}
                y={tooltip.y - 7.5}
                fill={color.primary.white}
                className="phone"
                fontSize="1.45"
                fontWeight="bold"
              >
                <a href={`tel: ${tooltip?.data?.phone}`}>{tooltip?.data?.phone}</a>
              </text>
              <text
                x={tooltip.x - 12}
                y={tooltip.y - 5}
                fill="#3DAE73"
                className="mail"
                fontSize="1.25"
                fontWeight="bold"
              >
                <a href={`mailto: ${tooltip?.data?.email}`}>{tooltip?.data?.email}</a>
              </text>
              <line
                x1={tooltip.x + 0.25}
                y1={tooltip.y - 1.2}
                x2={tooltip.x + 0.25}
                y2={tooltip.y + 1}
                stroke-width="0.3"
                stroke="#204370"
                stroke-dasharray="0.3"
              ></line>
            </g>
          )}
      </svg>
    </Map>
  );
};
